const BASE_API = '/api/v1'

// function normilizeParams(params, prefix) {
//   const str = Object.keys(params)
//                 .map((key) => {
//                   const value = params[key];
//                   const encodedKey = prefix ? `${prefix}[${encodeURIComponent(key)}]` : encodeURIComponent(key);

//                   if (typeof value === 'object' && value !== null) {
//                     return normilizeParams(value, encodedKey); // Recursively handle nested objects
//                   } else {
//                     return `${encodedKey}=${encodeURIComponent(value)}`;
//                   }
//                 }).join('&');
//   return str.length > 0 ? `?${str}` : ''
// }

window.Routes = {
  // events
  events: () => `/events`,

  // live
  liveFetchLayout: (params) => `/live`,
  liveSnapshot: (cameraId) => `/cameras/${cameraId}/live/snapshot?${new Date().getTime()}`,
  liveStream: (cameraId) => `/cameras/${cameraId}/live/stream?${new Date().getTime()}`,
  cameraPtz: (cameraId, action) => `/cameras/${cameraId}/ptz/${action}`
}


